import { addQueryStringData } from "@shared/utils/WebBrowser";
import { isSherbertAssetUrl } from "@design-stack-ct/assets-sdk";
import type { Designer } from "../../@types/designer";
import { Crop } from "../../@types/imagesCrop";

/** Copied from designer cropUtils */
function convert(value: number) {
    return Math.floor(value * 100);
}

/** Copied from designer cropUtils */
// MCP uploads crop data attribute is not the same as MCP rendering or locally in DCL so this provides a converter to be used in a crop request for uploads
function convertToUploadCrop(crop: Crop) {
    // crop is a comma separated list L, T, R, B
    return `${convert(crop.left)},${convert(crop.top)},${convert(crop.right)},${convert(crop.bottom)}`;
}

/** the rest of this, copied + simplified from designer embroiderImage */
function getUrl(fileid: string, designer: Designer) {
    const { uploadsUrlBuilder } = designer.clients.upload;
    return uploadsUrlBuilder.getPrintUrl(fileid);
}

function getOriginalUrlWithCrop(originalUrl: string, crop: Crop) {
    const value = convertToUploadCrop(crop);
    return addQueryStringData(originalUrl, { crop: value });
}

export const getImageUrl = async (url: string): Promise<{ uploadId: string }[]> => {
    const processingParameters = encodeURIComponent(JSON.stringify({ type: "image" }));
    const uploadsUrl = `https://uploads.documents.cimpress.io/v1/uploads?process=${processingParameters}&url=${url}&deleteAfterDays=1`;

    const response = await fetch(uploadsUrl, {
        method: "POST",
        body: url
    });
    const res = await response.json();
    return res;
};

export async function embroideryCropAction(originalUrl: string, crop: Crop, designer: Designer) {
    let resolvedImageUrl = originalUrl;
    if (isSherbertAssetUrl(originalUrl)) {
        // Sherbert assets don't support the crop querystring so we can't generate stitch instructions for cropped images
        // we have to upload to uploadsV1 then provide that to designer
        const imageUrl: { uploadId: string }[] = await getImageUrl(originalUrl);
        const uploadsId = imageUrl[0].uploadId;
        resolvedImageUrl = `https://uploads.documents.cimpress.io/v1/uploads/${uploadsId}?tenant=default`;
    }
    const imgUrl = getOriginalUrlWithCrop(resolvedImageUrl, crop);

    const response = await designer.clients.upload.embroideryImage(imgUrl);
    return getUrl(response[0].uploadId, designer);
}

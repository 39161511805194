import type { Designer } from "../../@types/designer";
import type { Crop, CropAttributes } from "../../@types/imagesCrop";
import { embroideryCropAction } from "./EmbroideryCropUtils";

// take into account embroidery and redigitizing with the crop results
async function accountForTechnology(
    newCrop: Crop,
    viewModel: ItemViewModel,
    designer: Designer
): Promise<{ crop: Crop; xtiUrl?: string; colorOverrides?: [] }> {
    if (designer && viewModel.canvasIsEmbroidery()) {
        viewModel.set({ reDigitizing: true });

        const xtiUrl = await embroideryCropAction(viewModel.model.get("originalUrl"), newCrop, designer);
        // TODO - proper re-map color overrides on a crop instead of clearing them
        return { crop: newCrop, xtiUrl, colorOverrides: [] };
    }
    return { crop: newCrop };
}

// submit crop for update through command dispatcher
export async function performCrop(
    newCrop: Crop,
    viewModel: ItemViewModel,
    designer: Designer,
    lockAspectRatio: boolean
) {
    const { crop, xtiUrl, colorOverrides } = await accountForTechnology(newCrop, viewModel, designer);
    // TODO: use a more general solution to maintain color overrides when segmented images all go through the same reprocessing steps
    const newAttributes: CropAttributes = colorOverrides ? { xtiUrl, colorOverrides } : { xtiUrl };

    designer &&
        designer.commandDispatcher.easelCropImage({
            attributes: newAttributes,
            viewModel,
            crop,
            lockAspectRatio
        });

    viewModel.set({
        reDigitizing: false,
        inspectionColors: false
    });
    designer && designer.eventBus.trigger("image:cropped", viewModel);
}

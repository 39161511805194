import React, { useRef, useCallback, useEffect, useState } from "react";
// @ts-ignore
// eslint-disable-next-line import/no-unresolved
import Cropper from "react-cropper";
import type { Crop, CropData, HTMLCropperElement } from "../../@types/imagesCrop";
import "./cropper.scss";

const MAX_CANVAS_HEIGHT = 370;

interface Props {
    imageUrl: string;

    // Previous crop or starting crop that cropper should start with
    initialCrop?: Crop;

    // Lock the aspect ratio in cropper
    lockAspectRatio?: boolean;

    // function that will be called every time crop changed
    onFinishResizing: (crop: Crop) => void;

    // set the image crop dialog to specific aspectRatio (0 mean no ratio and loss crop)
    aspectRatio?: number;

    // Height in pixels of the ImageCropper
    cropperHeight?: number;

    // Width in pixels of the ImageCropper
    cropperWidth?: number;
}

export function ImageCropper({
    imageUrl,
    initialCrop = { top: 0, left: 0, right: 0, bottom: 0 },
    aspectRatio = 0,
    lockAspectRatio = false,
    onFinishResizing,
    cropperHeight,
    cropperWidth
}: Props) {
    const cropperRef = useRef<HTMLCropperElement>();
    const [isResizing, setIsResizing] = useState(false);
    const firstUpdate = useRef(true);
    useEffect(() => {
        // Don't do anything the first time render because we are already doing it on cropper ready
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }

        if (!cropperRef.current) {
            return;
        }

        if (lockAspectRatio) {
            cropperRef.current.setAspectRatio(aspectRatio);
        } else {
            const currentCropData = cropperRef.current.getData();
            cropperRef.current.setAspectRatio(0);
            cropperRef.current.setData(currentCropData);
        }
    }, [aspectRatio, lockAspectRatio]);

    function getCropData(): CropData | undefined {
        if (!cropperRef.current) {
            return;
        }

        const imageData = cropperRef.current.getImageData();

        // Convert data into something cropper understand && Set initial crop
        // eslint-disable-next-line consistent-return
        return {
            x: initialCrop.left * imageData.naturalWidth,
            y: initialCrop.top * imageData.naturalHeight,
            width: imageData.naturalWidth * (1 - initialCrop.right - initialCrop.left),
            height: imageData.naturalHeight * (1 - initialCrop.bottom - initialCrop.top)
        };
    }

    const onCropperReady = useCallback(() => {
        // natural width/height stored in cim document is for original image while we are using
        // preview url image who's width/height can be different
        if (!cropperRef.current) {
            return;
        }
        // Convert data into something cropper understand && Set initial crop
        const initialCropData = getCropData();
        if (!initialCropData) {
            return;
        }

        if (lockAspectRatio) {
            cropperRef.current.setAspectRatio(aspectRatio);
        }
        cropperRef.current.setData(initialCropData);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialCrop]);

    // Designer fails to save the document if the crop is 100%
    const cropLimit = 0.9999;

    const onCrop = useCallback(
        event => {
            const cropRec = {
                left: Math.min(cropLimit, Math.max(event.detail.x / event.target.width, 0)),
                top: Math.min(cropLimit, Math.max(event.detail.y / event.target.height, 0)),
                right: Math.min(cropLimit, Math.max(1 - (event.detail.x + event.detail.width) / event.target.width, 0)),
                bottom: Math.min(
                    cropLimit,
                    Math.max(1 - (event.detail.y + event.detail.height) / event.target.height, 0)
                )
            };
            onFinishResizing(cropRec);
        },
        [onFinishResizing]
    );

    const onCropStart = () => {
        setIsResizing(true);
    };

    const onCropEnd = () => {
        setIsResizing(false);
    };

    return (
        <div className="easel-image-cropper-container">
            <Cropper
                className={isResizing ? "cropper-resizing" : undefined}
                ref={cropperRef}
                src={imageUrl}
                guides={true}
                crop={onCrop}
                style={{
                    width: cropperWidth ?? "100%",
                    height: cropperHeight ?? "37vh",
                    maxHeight: MAX_CANVAS_HEIGHT
                }}
                background={false}
                zoomable={false}
                scalable={false}
                dragMode={"none"}
                autoCropArea={1}
                viewMode={1}
                ready={onCropperReady}
                cropstart={onCropStart}
                cropend={onCropEnd}
            />
        </div>
    );
}
ImageCropper.displayName = "ImageCropper";

import React, { useEffect, useRef, useState } from "react";
import { Box } from "@vp/swan";
import { itemIsIcon, useDesigner } from "@designer-suite";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { UploadTypes } from "@shared/features/UploadsAndAssets";
import { EASEL_EVENTS } from "@utilities";
import { STUDIO_TRACKING_EVENTS, fireUserInteractionTrackingEvent } from "@shared/utils/Tracking";
import { CropDialog as CropDialogDisplayComponent } from "@shared/features/Crop";
import { ImageCropper } from "./ImageCropper";
import { performCrop } from "./CropUtils";
import type { Crop, ImageCanvasItem } from "../../@types/imagesCrop";
import "./cropDialog.scss";

const CropCache: Record<string, { lockAspectRatio: boolean }> = {};

export function CropDialog() {
    const designer = useDesigner();
    const [cropRect, setCropRect] = useState<Crop>({ top: 0, left: 0, right: 0, bottom: 0 });
    const [imageItem, setImageItem] = useState<ImageCanvasItem | null>(null);
    const [previewUrl, setPreviewUrl] = useState<string | null>(null);
    const [lockAspectRatio, setLockAspectRatio] = useState(false);
    const [initialAspectRatio, setInitialAspectRatio] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const [imageItemId, setImageItemId] = useState<string | null>(null);
    const { isSmall } = useStudioLayout();
    const startTime = useRef(0);

    useEffect(() => {
        if (!designer) {
            return;
        }

        const openModal = (selection: [ImageCanvasItem]) => {
            if (selection.length > 0) {
                setImageItem(selection[0]);
                setImageItemId(selection[0].id);
                const constrained = CropCache[selection[0].id]
                    ? CropCache[selection[0].id].lockAspectRatio
                    : selection[0]._itemViewModel.get("isConstrainedCrop");
                setLockAspectRatio(constrained);
                const boundBox = selection[0]._itemViewModel.get("handleBoundingBox");
                setInitialAspectRatio(boundBox.width / boundBox.height);

                setIsOpen(true);
            }
        };

        designer.eventBus.on(EASEL_EVENTS.REACT_CROPPER_OPEN, openModal);

        // eslint-disable-next-line consistent-return
        return () => {
            designer.eventBus.off(EASEL_EVENTS.REACT_CROPPER_OPEN, openModal);
        };
    }, [designer]);

    useEffect(() => {
        if (imageItem) {
            (async () => {
                setPreviewUrl(await (imageItem._itemViewModel as ImageViewModel)?.getUncroppedPreviewUrl(300));
            })();
        } else {
            setPreviewUrl(null);
        }
    }, [imageItem]);

    useEffect(() => {
        designer &&
            imageItem &&
            designer.eventBus.trigger(STUDIO_TRACKING_EVENTS.CROP_ASPECT_RATIO_LOCK_MODIFIED, {
                lockAspectRatio,
                imageType: itemIsIcon(imageItem) ? UploadTypes.ICON : UploadTypes.IMAGE
            });
        setLockAspectRatio(lockAspectRatio);
        if (imageItem) {
            CropCache[imageItem.id] = { lockAspectRatio };

            if (!lockAspectRatio && imageItem._itemViewModel.model.get("locked")) {
                imageItem._itemViewModel.model.set("locked", false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lockAspectRatio]);

    const closeDialog = () => {
        setLockAspectRatio(false);
        setImageItem(null);
        setIsOpen(false);
    };

    const onClickClose = () => {
        closeDialog();
        setImageItemId(null);
        designer && designer.eventBus.trigger(STUDIO_TRACKING_EVENTS.CROP_CANCELED);
    };

    useEffect(() => {
        const logTiming = (event: EventData) => {
            if (event.itemIds[0] === imageItemId && startTime.current > 0) {
                const endTime = performance.now();
                fireUserInteractionTrackingEvent("Crop Image", endTime - startTime.current);
            }
            setImageItemId(null);
        };

        let unsubscribeUpdate = () => {};
        if (designer && imageItemId) {
            unsubscribeUpdate = designer.api.events.subscribe(designer.api.events.eventTypes.ITEMS_CHANGED, logTiming);
        }

        return () => {
            unsubscribeUpdate();
        };
    }, [designer, startTime, imageItemId]);

    const onApply = () => {
        startTime.current = performance.now();

        closeDialog();
        if (imageItem && designer) {
            designer.eventBus.trigger(STUDIO_TRACKING_EVENTS.CROP_APPLIED, {
                imageType: itemIsIcon(imageItem) ? UploadTypes.ICON : UploadTypes.IMAGE
            });
            performCrop(cropRect, imageItem._itemViewModel, designer, lockAspectRatio);
        }
    };

    const cropperSideLength = isSmall ? 343 : 370;

    return (
        <CropDialogDisplayComponent
            isOpen={isOpen}
            lockAspectRatio={lockAspectRatio}
            onApply={onApply}
            onClickClose={onClickClose}
            setLockAspectRatio={setLockAspectRatio}
        >
            {imageItem && previewUrl && (
                <Box
                    className="easel-crop-dialog-cropper-container easel-crop-dialog-cropper-container-refresh"
                    marginY={1}
                    style={{
                        minWidth: cropperSideLength,
                        minHeight: "37vh"
                    }}
                >
                    <ImageCropper
                        imageUrl={previewUrl}
                        initialCrop={imageItem.crop}
                        lockAspectRatio={lockAspectRatio}
                        onFinishResizing={setCropRect}
                        aspectRatio={initialAspectRatio}
                    />
                </Box>
            )}
        </CropDialogDisplayComponent>
    );
}
CropDialog.displayName = "CropDialog";

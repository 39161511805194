import React, { ReactNode } from "react";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import {
    H3,
    Typography,
    ToggleSwitch,
    Button,
    FlexBox,
    Divider,
    LegacyModalDialog,
    LegacyModalDialogHeader,
    LegacyModalDialogBody,
    LegacyModalDialogButtons,
    LegacyModalDialogContent,
    LegacyModalDialogCloseButton,
    LegacyModalDialogFooter
} from "@vp/swan";
import classNames from "classnames";
import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import * as styles from "./CropDialog.module.scss";

const messages = defineMessages({
    dialogHeader: {
        id: "easel.components.image.cropDialog.header",
        defaultMessage: "Crop Image",
        description: {
            note: "Crop image dialog title"
        }
    },
    lockAspectRatio: {
        id: "easel.components.image.cropDialog.lockAspectRatio",
        defaultMessage: "Lock crop box to maintain design proportions",
        description: {
            note: "Checkbox to lock aspect ratio based on current design bounding box"
        }
    },
    apply: {
        id: "easel.components.image.cropDialog.apply",
        defaultMessage: "Apply",
        description: {
            note: "Apply crop changes"
        }
    },
    confirm: {
        id: "easel.components.image.cropDialog.confirm",
        defaultMessage: "Confirm",
        description: {
            note: "Confirm crop changes"
        }
    },
    cancel: {
        id: "easel.components.image.cropDialog.cancel",
        defaultMessage: "Cancel",
        description: {
            note: "Close dialog with out applying changes"
        }
    },
    closeAriaLabel: {
        id: "easel.components.image.cropDialog.closeAriaLabel",
        defaultMessage: "Close crop dialog"
    },
    lockAspectRatioToggle: {
        id: "easel.components.image.cropDialog.lockAspectRatioToggle",
        defaultMessage: "Lock aspect ratio",
        description: {
            note: "Toggle to lock aspect ratio based on current design bounding box"
        }
    },
    rotate: {
        id: "easel.components.image.cropDialog.rotate",
        defaultMessage: "Rotate 90°",
        description: {
            note: "Text on button to rotate image 90 degrees"
        }
    },
    cropModalAriaLabel: {
        id: "easel.components.image.cropDialog.cropModalAriaLabel",
        defaultMessage: "Crop Dialog",
        description: {
            note: "Text message is aria label of crop modal"
        }
    }
});

interface CropDialogProps {
    isOpen: boolean;
    onClickClose: () => void;
    onApply: () => void;
    lockAspectRatio: boolean;
    setLockAspectRatio: (value: boolean) => void;
    children: ReactNode | ReactNode[];
}

export function CropDialog({
    isOpen,
    lockAspectRatio,
    setLockAspectRatio,
    onClickClose,
    onApply,
    children
}: CropDialogProps) {
    const { t } = useTranslationSSR();
    const { isSmall } = useStudioLayout();

    return (
        <LegacyModalDialog
            isOpen={isOpen}
            onRequestDismiss={onClickClose}
            variant={isSmall ? "panel-bottom" : "standard"}
            takeOver={isSmall}
            data-dialog-enable-browser-history="xs-only"
            onClick={(event: any) => {
                event.stopPropagation();
            }}
            data-dcl-prevent-canvas-items-deselection
        >
            <LegacyModalDialogContent
                aria-label={t(messages.cropModalAriaLabel.id)}
                style={{ width: "530px" }}
                className={classNames(
                    "studio-modal-dialog-content crop-modal-dialog-content",
                    styles.cropModalDialogContent,
                    {
                        "mobile-panel-content": isSmall
                    }
                )}
                fullBleed
            >
                <LegacyModalDialogCloseButton visuallyHiddenLabel={t(messages.closeAriaLabel.id)} />
                <LegacyModalDialogHeader mb={5} ml={6} mt={isSmall ? 9 : 7}>
                    <H3>{t(messages.dialogHeader.id)}</H3>
                </LegacyModalDialogHeader>
                <Divider />
                <FlexBox className={classNames("easel-crop-dialog-options", styles.easelCropDialogOptions)}>
                    <ToggleSwitch activated={lockAspectRatio} onRequestActivatedChange={setLockAspectRatio} />
                    <Typography>{t(messages.lockAspectRatioToggle.id)}</Typography>
                </FlexBox>
                <LegacyModalDialogBody>{children}</LegacyModalDialogBody>
                <LegacyModalDialogFooter marginTop={1}>
                    <FlexBox justifyContent="center">
                        <LegacyModalDialogButtons>
                            <Button onClick={onClickClose} data-button-type="cancel" size="mini">
                                {t(messages.cancel.id)}
                            </Button>
                            <Button onClick={onApply} data-button-type="apply" skin="primary" size="mini">
                                {t(messages.confirm.id)}
                            </Button>
                        </LegacyModalDialogButtons>
                    </FlexBox>
                </LegacyModalDialogFooter>
            </LegacyModalDialogContent>
        </LegacyModalDialog>
    );
}
CropDialog.displayName = "CropDialogRefresh";
